import manajemenUsers from "@navigation/vertical/manajemen-users";
import manajemenSoal from "@navigation/vertical/bank-soal";
import manajemenTo from "@navigation/vertical/manajemen-try-out";
import manajemenKampus from "./manajemen-kampus";
import modul from "@/navigation/vertical/modul";
import invoice from "@/navigation/vertical/invoice";
import manajemenVideo from "./manajemen-video";
import manajemenMateri from "./manajemen-materi";
import manajemenEvent from "./manajemen-event";
import voucher from "./voucher";

export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
  },
  ...manajemenUsers,
  ...manajemenSoal,
  ...manajemenTo,
  ...manajemenMateri,
  ...manajemenVideo,
  ...manajemenEvent,
  ...manajemenKampus,
  ...invoice,
  ...modul,
  ...voucher,
];
